import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import React, { useState} from "react";
import { auth } from "../config/firebaseConfig";
import "./css/login.css";
import { Link } from "react-router-dom";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        alert("Successfully Logged In");
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const forgotPassword = () =>{
    sendPasswordResetEmail(auth, email).then(() => {
        alert("Check your email to change your password")
    }).catch((err) => {
        alert(err.message)
    })
}
  return (
    <div className="container-flex-centered">

      <div className="logincard">
        <h1>Login</h1>
        <div className="form-group">
          <input
            type="text"
            className="textbox"
            placeholder="Email Address"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            autoComplete="off"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="textbox"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            autoComplete="off"
          />
        </div>
        <div className="form-group btn-group">
          <button className="btn" onClick={handleLogin}>
            Login
          </button>
        </div>
        <div className="form-group">
        <Link to="/register" className="link">Dont Have an Account? <br/> Please Register</Link>
        </div>
        <div className="form-group">
        <p onClick={forgotPassword} className="link">Forgot your password?</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
