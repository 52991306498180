import React, { useState } from 'react';
import {auth,db} from '../config/firebaseConfig'
import {createUserWithEmailAndPassword} from 'firebase/auth'
import {doc, setDoc} from 'firebase/firestore'
import { Link } from 'react-router-dom'

import './css/login.css'
const Register = () => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [name, setName] = useState("");    
    const [password, setPassword] = useState("");
    
    const handleSignup = () =>{
        createUserWithEmailAndPassword(auth,email,password).then(
            setDoc(doc(db,"users",email),{
                name:name,
                email:email,
                phone:phone,
                address:address,
                payed:[],
                registerdAt: new Date().toISOString()
            }).catch((err) => {
                alert(err.message)
            })

        ).then(
            () => {
             alert("Successfully Registered")   
            }
        ).catch((err) => {
            alert(err.message)
            window.location.href="/"
        })
    }


    return (
        
        <div className="container-flex-centered">
        <div className="logincard">
        <h1>Register</h1>
        <div className="form-group">
        <input type="email" className="textbox" autoComplete="off" required placeholder="Email Address"  onChange={(e)=>{setEmail(e.target.value.toLowerCase())}} />
        </div>
        <div className="form-group">
        <input type="text" className="textbox" autoComplete="off" required placeholder="Full Name" onChange={(e)=>{setName(e.target.value)}} />
        </div>
        <div className="form-group">
        <input type="text" className="textbox" autoComplete="off" required placeholder="Phone Number" onChange={(e)=>{setPhone(e.target.value)}} />
        </div>
        <div className="form-group">
        <input type="text" className="textbox" autoComplete="off" required placeholder="Address" onChange={(e)=>{setAddress(e.target.value)}} />
        </div>
        <div className="form-group">
        <input type="password" className="textbox" autoComplete="off" required placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}} />
        </div>
        <div className="form-group btn-group">
        <button className="btn" onClick={handleSignup}>Register</button>
        </div>
        <div className="form-group">
        <Link to="/" className="link">Have an Account? <br/> Please Login</Link>
        </div>
        </div>
        </div>
    );
}

export default Register;
